import React, { useRef, useState, useEffect } from "react";
import { Html } from "@react-three/drei";
import { useTexture } from "@react-three/drei";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import Alert from "@mui/material/Alert";
import $ from "jquery";
import Tooltip from "@mui/material/Tooltip";
import { proxy, useSnapshot } from "valtio";
import state from "./state";
import stateRechteckig from "./state-Rechteckig";

export default function Formular() {
  var [cartData, setCartData] = React.useState();
  var [cartDataB2B, setCartDataB2B] = React.useState();
  var [userDataB2B10, setUserDataB2B10] = React.useState([]);
  var [userDataB2B, setUserDataB2B] = React.useState([]);
  var [materialSelect, setMaterialSelect] = React.useState("Dekorplatte");
  const snap = useSnapshot(state);
  const snap2 = useSnapshot(stateRechteckig);
  var replaceSKU = state.SKU.replace("_", "/");
  /*useEffect(() => {
    async function getData() {
      await fetch("/wp-json/wc/v3/customers?role=b2b-10", {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              "ck_b57446e612e86bb954892ccfbca4144344a1bd3f:cs_523702a5e4edf94b43e288a129c3d5b5a0f08cb0"
            ),
        },
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          var initialArray = [];
          responseJSON.forEach(function (obj) {
            initialArray = [...initialArray, obj["billing"]["email"]];
          });
          setUserDataB2B10(initialArray);
        });
    }
    getData();
  }, []);*/
/*
  useEffect(() => {
    async function getB2BData() {
      await fetch("/wp-json/wc/v3/customers?per_page=100", {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              "ck_b57446e612e86bb954892ccfbca4144344a1bd3f:cs_523702a5e4edf94b43e288a129c3d5b5a0f08cb0"
            ),
        },
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          var initialArrayB2B = [];
          responseJSON.forEach(function (obj) {
            if (obj["role"] === "b2b-customer") {
              initialArrayB2B = [...initialArrayB2B, obj["email"]];
            }
          });

          setUserDataB2B(initialArrayB2B);
        });
    }

    getB2BData();
  }, []);*/
/*
  useEffect(() => {
    async function getCartSession() {
      await fetch("/wp-json/cocart/v2/cart", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          setCartData(
            responseJSON["customer"]["billing_address"]["billing_email"]
          );
        });
    }
    getCartSession();
  }, []);

  useEffect(() => {
    async function getCartSessionB2B() {
      await fetch("/wp-json/cocart/v2/cart", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          setCartDataB2B(
            responseJSON["customer"]["billing_address"]["billing_email"]
          );
        });
    }
    getCartSessionB2B();
  }, []);
*/
  useEffect(() => {
    const interval = setInterval(() => {
      state.x(state.x + 1);
    }, 420000);

    return () => {
      clearInterval(interval);
    };
  });

  window.onload = function () { 
    if (document.referrer.includes("topweiss-fh")) {
      state.SKU = "0112 FH"
    }
    else if (document.referrer.includes("topweiss-mt")) {
      state.SKU = "0112 MT"
    }
    else if (document.referrer.includes("graphitgrau")) {
      state.SKU = "2162 PE"
    }
    else if (document.referrer.includes("birkengrau")) {
      state.SKU = "0741 FH"
    }
    else if (document.referrer.includes("chalet-eiche")) {
      state.SKU = "35252 AT"
    }
    else if (document.referrer.includes("champagner")) {
      state.SKU = "27045 BS"
    }
    else if (document.referrer.includes("schwarz")) {
      state.SKU = "0080 FH"
    }
    else if (document.referrer.includes("fresko")) {
      state.SKU = "0071 FH"
    }
    else if (document.referrer.includes("achatgrau")) {
      state.SKU = "2171 PE"
    }
    else if (document.referrer.includes("gletscherweiss-matt")) {
      state.SKU = "2306 MT"
    }
    else if (document.referrer.includes("weiss-spiegelglanz")) {
      state.SKU = "0085 Weiss SG"
    }
    else if (document.referrer.includes("eiche-sanremo-classic")) {
      state.SKU = "34140 RV"
    }
    else if (document.referrer.includes("betonart-perlgrau")) {
      state.SKU = "44375 DP"
    }
    else if (document.referrer.includes("betonart-schiefergrau")) {
      state.SKU = "44405 DP"
    }
    else if (document.referrer.includes("atram-eiche")) {
      state.SKU = "0665 NI"
    }
    else if (document.referrer.includes("obirbuche-geplankt")) {
      state.SKU = "0157 NA"
    }
    else if (document.referrer.includes("eiche-sawcut-hell-su")) {
      state.SKU = "0877 SU"
    }
    else if (document.referrer.includes("oxid-dark-brown")) {
      state.SKU = "K5579 DP"
    }
    else if (document.referrer.includes("eiche-sanremo-tabaco")) {
      state.SKU = "34138 RV"
    }
    else if (document.referrer.includes("cello")) {
      state.SKU = "0811 NA"
    }
    else if (document.referrer.includes("/wandboard-sanitaerweiss/")) {
      state.SKU = "0733 FH"
    }
    else if (document.referrer.includes("winterweiss")) {
      state.SKU = "0851 FH"
    }
    else if (document.referrer.includes("gletscherweiss")) {
      state.SKU = "2306 FH"
    }
    else if (document.referrer.includes("tundra-birke")) {
      state.SKU = "0171 NA"
    }
    else if (document.referrer.includes("zuria-white")) {
      state.SKU = "L005 LO"
    }
    else if (document.referrer.includes("raven-black")) {
      state.SKU = "L007 LO"
    }
    else if (document.referrer.includes("silberfichte-natur")) {
      state.SKU = "0920 SU"
    }
    else if (document.referrer.includes("eiche-evoke-sunset")) {
      state.SKU = "K5574 IR"
    }
    else if (document.referrer.includes("basalt")) {
      state.SKU = "2166 DP"
    }
    else if (document.referrer.includes("cottage-pine-antique")) {
      state.SKU = "K4347 AT"
    }
    else if (document.referrer.includes("kernbuche")) {
      state.SKU = "37706 NM"
    }
    else if (document.referrer.includes("astfichte")) {
      state.SKU = "0175 NA"
    }
    else if (document.referrer.includes("noce-sizilia")) {
      state.SKU = "37755 BS"
    }
    else if (document.referrer.includes("native-oak-light")) {
      state.SKU = "K4410 AW"
    }
    else if (document.referrer.includes("cloud-grey")) {
      state.SKU = "L009 LO"
    }
    else if (document.referrer.includes("eiche-endgrain-cognac")) {
      state.SKU = "K5413 RO"
    }
    else if (document.referrer.includes("eiche-evoke-trend")) {
      state.SKU = "K4421 IR"
    }
    else if (document.referrer.includes("canadian-eiche-su")) {
      state.SKU = "4057 SU"
    }
    else if (document.referrer.includes("eiche-endgrain-raw")) {
      state.SKU = "K5412 RO"
    }
    else if (document.referrer.includes("cameroon-brown")) {
      state.SKU = "25726 NM"
    }
    else if (document.referrer.includes("sanitaerweiss-spiegelglanz")) {
      state.SKU = "0733 SG"
    }
    else if (document.referrer.includes("eiche-evoke-fossil")) {
      state.SKU = "K5575 IR"
    }
    else if (document.referrer.includes("cross-steel-gt")) {
      state.SKU = "K5802 GT"
    }
    else if (document.referrer.includes("black-green")) {
      state.SKU = "25735 NM"
    }
    else if (document.referrer.includes("kieselgrau")) {
      state.SKU = "0742 FH"
    }
    else if (document.referrer.includes("eiche-endgrain-classic")) {
      state.SKU = "K5414 RO"
    }
    else if (document.referrer.includes("textile-graphite")) {
      state.SKU = "K5806 GT"
    }
    else if (document.referrer.includes("aluminium")) {
      state.SKU = "1300 FH"
    }

    else if (document.referrer.includes("schwarz-ni")) {
      state.SKU = "0080 NI"
    }
    else if (document.referrer.includes("lugano-esche-naturhell")) {
      state.SKU = "H1204 ST19"
    }
    else if (document.referrer.includes("halifax-eiche-natur")) {
      state.SKU = "H 1180 ST37"
    }
    else if (document.referrer.includes("rifteiche-greige")) {
      state.SKU = "H1371 ST19"
    }
    else if (document.referrer.includes("abano-esche-braun")) {
      state.SKU = "H1227 TM12"
    }
    else if (document.referrer.includes("casella-eiche-braun")) {
      state.SKU = "H1386 ST40"
    }
    else if (document.referrer.includes("casella-eiche-natur")) {
      state.SKU = "H1385 ST40"
    }
    else if (document.referrer.includes("vicenza-eiche")) {
      state.SKU = "H3157 ST12"
    }
    else if (document.referrer.includes("cuneo-eiche-braun")) {
      state.SKU = "H3317 ST28"
    }
    else if (document.referrer.includes("nevada-greige")) {
      state.SKU = "L013 AP"
    }
    else if (document.referrer.includes("fichte-washed-white")) {
      state.SKU = "K2342 AT"
    }
    else if (document.referrer.includes("darwin-grey")) {
      state.SKU = "L022 AP"
    }
    else if (document.referrer.includes("linen-beige")) {
      state.SKU = "L030 AP"
    }
    else if (document.referrer.includes("mandelbeige")) {
      state.SKU = "U211 ST9"
    }
    else if (document.referrer.includes("sandgelb")) {
      state.SKU = "U125 ST9"
    }
    else if (document.referrer.includes("kieselgrau")) {
      state.SKU = "U201 ST9"
    }
    else if (document.referrer.includes("rostrot")) {
      state.SKU = "U335 ST9"
    }
    else if (document.referrer.includes("nebelblau")) {
      state.SKU = "U502 ST9"
    }
    else if (document.referrer.includes("steingruen")) {
      state.SKU = "U665 ST9"
    }
    else if (document.referrer.includes("fossilgrau")) {
      state.SKU = "U769 ST9"
    }
    else if (document.referrer.includes("schwarz")) {
      state.SKU = "U999 ST7"
    }
    else if(document.referrer.includes("/wandboard-eiche-crack-furniert/")){
      state.SKU = "EICRfu105L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("wildeiche")){
      state.SKU = "WEifu109L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("/wandboard-eiche-furniert/")){
      state.SKU = "Eifu106L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("/wandboard-buche-furniert/")){
      state.SKU = "BUfu103L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("ahorn-europ")){
      state.SKU = "AHfu101L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("splintnuss")){
      state.SKU = "NU Sp FU 108L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("/wandboard-eiche-crack-bronce-furniert/")){
      state.SKU = "EICRBFu104L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("kernbuche")){
      state.SKU = "Bu K Fu107L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    else if(document.referrer.includes("birke-messer")){
      state.SKU = "BIfu102L"
      state.materialSelect = "Echtholz-Furniert"
      state.boardType = "26mm"
    }
    }

  const handleSKUChange = (event) => {
    state.SKU = event.target.value;
  };

  
  const handleProductIDChange = () => {
    for (let i = 0; i < state.products.length; i++) {
      if (state.SKU === state.dekor[i] && state.materialSelect === "Dekorplatte") {
        state.productID = state.products[i];
      }
      else if (state.SKU === state.dekor[i] && state.materialSelect === "Echtholz-Furniert") {
        state.productID = state.productsFurnier[i];
      }
    }
  };

  const handleSkuNameChange = () => {
    if (state.materialSelect === "Dekorplatte") {
      if(state.SKU === "0085 Weiss SG"){
        state.valueEdge = "1mm";
      }
      else{
        state.valueEdge = "2mm";
      }
        for (let i = 0; i < state.dekor.length; i++) {
        if (state.SKU === state.dekor[i]) {
          state.skuName = state.dekorName[i];
        }
      }
    } else if (state.materialSelect === "Echtholz-Furniert") {
      state.valueEdge = "1.5mm";
      for (let i = 0; i < state.dekorFurnier.length; i++) {
        if (state.SKU === state.dekorFurnier[i]) {
          state.skuName = state.dekorNameFurnier[i];
        }
      }
    }
  };
 

  const handleBoardTypeChange = (event) => {
    state.boardType = event.target.value;
  };

  /*const handleCheckEmpty = () => {
      if(document.getElementById("board_type") === ''){
        setSKU('35252 AT')
      }
      else if(setBoardType() === ''){
        setBoardType('19mm')
      }
      else if(setValueEdge() === ''){
        setValueEdge('2mm')
      }
    };*/

  const handleMaterialSelectChange = (event) => {
    state.materialSelect = event.target.value;
  };

  const handleBlurLengthL1 = () => {
    if (stateRechteckig.valueLengthL1 < 660) {
      stateRechteckig.valueLengthL1 = 660;
    } else if (stateRechteckig.valueLengthL1 > 1780) {
      stateRechteckig.valueLengthL1 = 1780;
    }
    
  };

  const handleBlurWidthB1 = () => {
    if (stateRechteckig.valueWidthB1 < 140) {
      stateRechteckig.valueWidthB1 = 140;
    } else if (stateRechteckig.valueWidthB1 > 380) {
      stateRechteckig.valueWidthB1 = 380;
    }
  };

  var requestOptions = {
    method: "GET",
  };

  fetch(
    "https://api.geoapify.com/v1/ipinfo?&apiKey=ce2cbcca7d4d4e2188307e9c9789ca95",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.country.name === "Germany") {
        state.tax(19);
        if(stateRechteckig.valueLengthL1 > 2100 && stateRechteckig.valueWidthB1 >= 80 && state.price < 300){
          state.freight_cost = 99.90;}
          
        else if(stateRechteckig.valueLengthL1 > 2000 && stateRechteckig.valueWidthB1 > 100 && state.price < 300){
          state.freight_cost = 99.90;}
          
        else if(stateRechteckig.valueLengthL1 > 1900 && stateRechteckig.valueWidthB1 > 200 && state.price < 300){
          state.freight_cost = 99.90;}
          
        else if(stateRechteckig.valueLengthL1 > 1800 && stateRechteckig.valueWidthB1 > 300 && state.price < 300){
          state.freight_cost = 99.90;}
          
          
        else if(stateRechteckig.valueLengthL1 > 1700 && stateRechteckig.valueWidthB1 > 400 && state.price < 300){
          state.freight_cost = 99.90;}
    
        else if(stateRechteckig.valueLengthL1 > 1600 && stateRechteckig.valueWidthB1 > 500 && state.price < 300){
          state.freight_cost = 99.90;
        } 
      else if(stateRechteckig.valueLengthL1 > 1500 && stateRechteckig.valueWidthB1 > 600 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 1400 && stateRechteckig.valueWidthB1 > 700 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 1300 && stateRechteckig.valueWidthB1 > 800 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 1200 && stateRechteckig.valueWidthB1 > 900 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 1100 && stateRechteckig.valueWidthB1 > 1000 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 1000 && stateRechteckig.valueWidthB1 > 1100 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 900 && stateRechteckig.valueWidthB1 > 1200 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      else if(stateRechteckig.valueLengthL1 > 800 && stateRechteckig.valueWidthB1 >= 1300 && stateRechteckig.price < 300){
        state.freight_cost = 99.90;
      }
      }
    })
    .catch((error) => console.log("error", error));

    if(stateRechteckig.valueLengthL1 > 2100 && stateRechteckig.valueWidthB1 >= 80 && state.price < 300){
      state.freight_cost = 79.90;}
      
    else if(stateRechteckig.valueLengthL1 > 2000 && stateRechteckig.valueWidthB1 > 100 && state.price < 300){
      state.freight_cost = 79.90;}
      
    else if(stateRechteckig.valueLengthL1 > 1900 && stateRechteckig.valueWidthB1 > 200 && state.price < 300){
      state.freight_cost = 79.90;}
      
    else if(stateRechteckig.valueLengthL1 > 1800 && stateRechteckig.valueWidthB1 > 300 && state.price < 300){
      state.freight_cost = 79.90;}
      
      
    else if(stateRechteckig.valueLengthL1 > 1700 && stateRechteckig.valueWidthB1 > 400 && state.price < 300){
      state.freight_cost = 79.90;}

    else if(stateRechteckig.valueLengthL1 > 1600 && stateRechteckig.valueWidthB1 > 500 && state.price < 300){
      state.freight_cost = 79.90;
    } 
    
    else if(stateRechteckig.valueLengthL1 > 1500 && stateRechteckig.valueWidthB1 > 600 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 1400 && stateRechteckig.valueWidthB1 > 700 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 1300 && stateRechteckig.valueWidthB1 > 800 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 1200 && stateRechteckig.valueWidthB1 > 900 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 1100 && stateRechteckig.valueWidthB1 > 1000 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 1000 && stateRechteckig.valueWidthB1 > 1100 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 900 && stateRechteckig.valueWidthB1 > 1200 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    else if(stateRechteckig.valueLengthL1 > 800 && stateRechteckig.valueWidthB1 >= 1300 && stateRechteckig.price < 300){
      state.freight_cost = 79.90;
    }
    
    else if(stateRechteckig.valueLengthL1 >= 1000 && stateRechteckig.valueLengthL1 < 1500){
      state.tablarPrice = 45
    }
    else if(stateRechteckig.valueLengthL1 >= 1500){
      state.tablarPrice = 60
    }
    else if(stateRechteckig.valueLengthL1 >= 1000 && stateRechteckig.valueLengthL1 < 1500 && state.boardType === '38mm'){
      state.tablarPrice = 33
    }

    else if(stateRechteckig.valueLengthL1 >= 1500 && stateRechteckig.valueLengthL1 < 1500 && state.boardType === '38mm'){
      state.tablarPrice = 44
    }

    else if(stateRechteckig.valueLengthL1 < 1000 && state.boardType === '38mm'){
      state.tablarPrice = 22
    }

    else{
      state.freight_cost = 0.00;
      state.tablarPrice = 30
    }
    


  var meta = {
    sku: replaceSKU,
    board_type: state.boardType,
    edge_type: state.valueEdge,
    length: stateRechteckig.valueLengthL1 / 10,
    width: stateRechteckig.valueWidthB1 / 10,
    individualFactor: state.factor,
    tax: state.tax,
  };

  async function apiData() {
    if (userDataB2B.includes(cartDataB2B)) {
      meta.individualFactor = 1.536;
    } else if (userDataB2B10.includes(cartData)) {
      meta.individualFactor = 1.1;
    } else {
      meta.individualFactor = 2.6;
    }


    //console.log(JSON.stringify(meta))

    const res = await fetch(
      "https://api.woodchecker.com:8443/PriceCalculationAPI-3/api/boardRectanglePrice",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "x-api-key": "ValidApiKey",
        },

        body: JSON.stringify(meta),
      }
    ).catch((err) => {
      state.isDisabled = true;
    });

    if (res.ok) {
      state.isDisabled = false;
    }
    const apiPrice = await res.json();

      state.price = apiPrice + state.tablarPrice + 45;
    
  }



  React.useEffect(() => {
    apiData();
    handleSkuNameChange();
    handleProductIDChange();
  }, [apiData]);

  function reloadP() {
    window.location.href = "https://www.woodchecker.com/cart/";
  }

   
  function addToCart() {
    $.ajax({
      url: "https://www.woodchecker.com/wp-json/cocart/v2/cart/add-item",
      method: "POST",
      data: JSON.stringify({
        id: state.productID,
        price: '"' + state.price + '"',
        quantity: state.quantity,
        item_data: {
          custom_price: state.price,
          length: stateRechteckig.valueLengthL1,
          width: stateRechteckig.valueWidthB1,
          "board-type": state.boardType,
          sku_name: state.skuName,
          sku: state.SKU,
          edge_type: state.valueEdge,
        },
      }),
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      complete: setTimeout(() => {
        reloadP();
      }, 3000),
      error: function (XMLHttpRequest, textStatus, errorThrown, isDisabled) {
        isDisabled = true;
      },
    });
  }

  return [
    <>
      <div className="ui-wrapper">
        <div className="mdc-card">
          <Box sx={{ width: 300 }}>
            <FormControl fullWidth>
              <Typography>Material</Typography>
              <Select
                labelId="dekor_selection_label"
                id="dekorselection"
                value={state.materialSelect}
                label="dekorauswahl"
                onChange={(e) => {
                  handleMaterialSelectChange(e);
                }}
              >
                <MenuItem value={"Dekorplatte"} id="0112 FH">
                  <Typography className="thumbnail-text">
                    &nbsp;Dekorplatte
                  </Typography>
                </MenuItem>
                <MenuItem value={"Echtholz-Furniert"} id="0112 FH">
                  <Typography className="thumbnail-text">
                    &nbsp;Echtholz-Furniert
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          {state.materialSelect === "Dekorplatte" && (
            <Box sx={{ width: 300 }}>
              <FormControl fullWidth>
                <Typography>
                  Oberfläche
                </Typography>
                <Select
                  labelId="board_type_label"
                  id="board_type"
                  value={state.SKU}
                  label="Plattentyp"
                  onChange={(e) => {
                    handleSKUChange(e);
                    handleSkuNameChange(e);
                    handleProductIDChange(e);
                  }}
                >
                  <MenuItem value={"0112 FH"} id="0112 FH">
                    <Avatar
                      alt="Topweiss FH"
                      src="https://cdn.shopify.com/s/files/1/0758/0962/0298/files/0112_FH.jpg?v=1686650835"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Topweiss Feinhammerschlag
                    </Typography>
                  </MenuItem>
                  {/*
                  <MenuItem value={"0112 MT"} id="0112 MT">
                    <Avatar
                      alt="Topweiss MT"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0112 MT.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Topweiss Matt
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"2162 PE"} id="2162 PE">
                    <Avatar
                      alt="Graphitgrau"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/2162 PE.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Graphitgrau
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"0741 FH"} id="0741 FH">
                    <Avatar
                      alt="Birkengrau"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0741 FH.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Birkengrau
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"35252 AT"} id="35252 AT">
                    <Avatar
                      alt="Chalet Eiche"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/35252 AT.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Chalet Eiche
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"27045 BS"} id="27045 BS">
                    <Avatar
                      alt="Champagner"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/27045 BS.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Champagner
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"0071 FH"} id="0071 FH">
                    <Avatar
                      alt="Fresko"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0071 FH.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Fresko
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"2171 PE"} id="2171 PE">
                    <Avatar
                      alt="Achatgrau"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/2171 PE.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Achatgrau
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"2306 MT"} id="2306 MT">
                    <Avatar
                      alt="Gletscherweiss Matt"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/2306 MT.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Gletscherweiss Matt
                    </Typography>
                  </MenuItem>
                  {(state.boardType === "19mm" ||
                    state.boardType === "25mm") && (
                    <MenuItem value={"0085 Weiss SG"} id="0085 Weiss SG">
                      <Avatar
                        alt="Weiss Spiegelglanz"
                        src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0085 Weiss SG.jpg"
                      />
                      <Typography className="thumbnail-text">
                        &nbsp;Weiss Spiegelglanz
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem value={"34140 RV"} id="34140 RV">
                    <Avatar
                      alt="Eiche Sanremo Classic"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/34140 RV.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Eiche Sanremo Classic
                    </Typography>
                  </MenuItem>
                  {(state.boardType === "19mm" ||
                    state.boardType === "38mm") && (
                    <MenuItem value={"44375 DP"} id="44375 DP">
                      <Avatar
                        alt="Beton Art Perlgrau"
                        src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/44375 DP.jpg"
                      />
                      <Typography className="thumbnail-text">
                        &nbsp;Beton Art Perlgrau
                      </Typography>
                    </MenuItem>
                  )}
                  {(state.boardType === "19mm" || state.boardType === "38mm") && (
                    <MenuItem value={"44405 DP"} id="44405 DP">
                      <Avatar
                        alt="Beton Art Schiefergrau"
                        src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/44405 DP.jpg"
                      />
                      <Typography className="thumbnail-text">
                        &nbsp;Beton Art Schiefergrau
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem value={"0665 NI"} id="0665 NI">
                    <Avatar
                      alt="Atram Eiche"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0665 NI.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Atram Eiche
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"0157 NA"} id="0157 NA">
                    <Avatar
                      alt="Obirbuche geplankt"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0157 NA.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Obirbuche geplankt
                    </Typography>
                  </MenuItem>
                  {(state.boardType === "19mm" ||
                    state.boardType === "38mm") && (
                    <MenuItem value={"K5579 DP"} id="K5579 DP">
                      <Avatar
                        alt="Oxid Dark Brown"
                        src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5579 DP.jpg"
                      />
                      <Typography className="thumbnail-text">
                        &nbsp;Oxid Dark Brown
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem value={"34138 RV"} id="34138 RV">
                    <Avatar
                      alt="Eiche Sanremo Tabaco"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/34138 RV.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Eiche Sanremo Tabaco
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"0811 NA"} id="0811 NA">
                    <Avatar
                      alt="Cello"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0811 NA.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Cello
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"0733 FH"} id="0733 FH"><Avatar alt="Sanitärweiss" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0733 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Sanitärweiss</Typography></MenuItem>
                <MenuItem value={"0851 FH"} id="0851 FH"><Avatar alt="Winterweiss" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0851 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Winterweiss</Typography></MenuItem>
                <MenuItem value={"2306 FH"} id="2306 FH"><Avatar alt="Gletscherweiss" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/2306 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Gletscherweiss Feinhammerschlag</Typography></MenuItem>
                <MenuItem value={"0171 NA"} id="0171 NA"><Avatar alt="Tundra Birke" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0171 NA.jpg" /><Typography className="thumbnail-text">&nbsp;Tundra Birke</Typography></MenuItem>
                <MenuItem value={"0920 SU"} id="0920 SU"><Avatar alt="Silberfichte natur SU" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0920 SU.jpg" /><Typography className="thumbnail-text">&nbsp;Silberfichte natur SU</Typography></MenuItem>
                <MenuItem value={"K5574 IR"} id="K5574 IR"><Avatar alt="Eiche Evoke Sunset" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5574 IR.jpg" /><Typography className="thumbnail-text">&nbsp;Eiche Evoke Sunset</Typography></MenuItem>
                {(state.boardType === "19mm" || state.boardType === "38mm") &&
                  <MenuItem value={"2166 DP"} id="2166 DP"><Avatar alt="Basalt" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/2166 DP.jpg" /><Typography className="thumbnail-text">&nbsp;Basalt</Typography></MenuItem>
                }
                {/*(state.boardType === "19mm") &&
                  <MenuItem value={"Superfront S1 - 0112 MT"} id="Superfront S1 - 0112 MT"><Avatar alt="Topweiss matt Superfront" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/Superfront S1 - 0112 MT.jpg" /><Typography className="thumbnail-text">&nbsp;Topweiss matt Superfront</Typography></MenuItem>
              */}
                {/*(state.boardType === "19mm") &&
                  <MenuItem value={"Superfront S1 - 0112 FH"} id="Superfront S1 - 0112 FH"><Avatar alt="Topweiss FH Superfront" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/Superfront S1 - 0112 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Topweiss FH Superfront</Typography></MenuItem>
            }
                <MenuItem value={"K4347 AT"} id="K4347 AT"><Avatar alt="Cottage Pine Antique" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K4347 AT.jpg" /><Typography className="thumbnail-text">&nbsp;Cottage Pine Antique</Typography></MenuItem>
                <MenuItem value={"0175 NA"} id="0175 NA"><Avatar alt="Astfichte" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0175 NA.jpg" /><Typography className="thumbnail-text">&nbsp;Astfichte</Typography></MenuItem>
                <MenuItem value={"37706 NM"} id="37706 NM"><Avatar alt="Kernbuche" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/37706 NM.jpg" /><Typography className="thumbnail-text">&nbsp;Kernbuche</Typography></MenuItem>
                <MenuItem value={"37755 BS"} id="37755 BS"><Avatar alt="Noce Sizilia" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/37755 BS.jpg" /><Typography className="thumbnail-text">&nbsp;Noce Sizilia</Typography></MenuItem>
                {(state.boardType === "19mm" || state.boardType === "25mm" || state.boardType === "38mm") &&
                  <MenuItem value={"25726 NM"} id="25726 NM"><Avatar alt="Cameroon Brown" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/25726 NM.jpg" /><Typography className="thumbnail-text">&nbsp;Cameroon Brown</Typography></MenuItem>
                }
                <MenuItem value={"K5575 IR"} id="K5575 IR"><Avatar alt="Eiche Evoke Fossil" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5575 IR.jpg" /><Typography className="thumbnail-text">&nbsp;Eiche Evoke Fossil</Typography></MenuItem>
                {(state.boardType === "19mm" || state.boardType === "25mm") &&
                  <MenuItem value={"0733 SG"} id="0733 SG"><Avatar alt="Sanitärweiss Spiegelglanz" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0733 SG.jpg" /><Typography className="thumbnail-text">&nbsp;Sanitärweiss Spiegelglanz</Typography></MenuItem>
                }
                {(state.boardType === "8mm" || state.boardType === "19mm" || state.boardType === "38mm") &&
                  <MenuItem value={"K5802 GT"} id="K5802 GT"><Avatar alt="Cross Steel GT" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5802 GT.jpg" /><Typography className="thumbnail-text">&nbsp;Cross Steel GT</Typography></MenuItem>
                }
                <MenuItem value={"0742 FH"} id="0742 FH"><Avatar alt="Kieselgrau" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0742 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Kieselgrau</Typography></MenuItem>
                <MenuItem value={"K5414 RO"} id="K5414 RO"><Avatar alt="Eiche Endgrain Classic" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5414 RO.jpg" /><Typography className="thumbnail-text">&nbsp;Eiche Endgrain Classic</Typography></MenuItem>
                {(state.boardType === "8mm" || state.boardType === "19mm" || state.boardType === "38mm") &&
                  <MenuItem value={"K5806 GT"} id="K5806 GT"><Avatar alt="Textile Graphite" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K5806 GT.jpg" /><Typography className="thumbnail-text">&nbsp;Textile Graphite</Typography></MenuItem>
                }
                {(state.boardType === "19mm" || state.boardType === "38mm") &&
                  <MenuItem value={"1300 FH"} id="1300 FH"><Avatar alt="Aluminium" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/1300 FH.jpg" /><Typography className="thumbnail-text">&nbsp;Aluminium</Typography></MenuItem>
                }
                
                <MenuItem value={"0080 NI"} id="0080 NI"><Avatar alt="Schwarz NI" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/0080 NI.jpg" /><Typography className="thumbnail-text">&nbsp;Schwarz NI</Typography></MenuItem>
                <MenuItem value={"H 1180 ST37"} id="H 1180 ST37"><Avatar alt="Halifax Eiche Natur" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1180 ST37.jpg" /><Typography className="thumbnail-text">&nbsp;Halifax Eiche Natur</Typography></MenuItem>
                <MenuItem value={"H1204 ST19"} id="H1204 ST19"><Avatar alt="Lugano Esche Naturhell" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1204 ST19.jpg" /><Typography className="thumbnail-text">&nbsp;Lugano Esche Naturhell</Typography></MenuItem>
                <MenuItem value={"H1227 TM12"} id="H1227 TM12"><Avatar alt="Abano Esche Braun" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1227 TM12.jpg" /><Typography className="thumbnail-text">&nbsp;Abano Esche Braun</Typography></MenuItem>
                <MenuItem value={"H1371 ST19"} id="H1371 ST19"><Avatar alt="Rifteiche Greige" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1371 ST19.jpg" /><Typography className="thumbnail-text">&nbsp;Rifteiche Greige</Typography></MenuItem>
                <MenuItem value={"H1385 ST40"} id="H1385 ST40"><Avatar alt="Casella Eiche Natur" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1385 ST40.jpg" /><Typography className="thumbnail-text">&nbsp;Casella Eiche Natur</Typography></MenuItem>
                <MenuItem value={"H1386 ST40"} id="H1386 ST40"><Avatar alt="Casella Eiche Braun" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H1386 ST40.jpg" /><Typography className="thumbnail-text">&nbsp;Casella Eiche Braun</Typography></MenuItem>
                <MenuItem value={"H3157 ST12"} id="H3157 ST12"><Avatar alt="Vicenza Eiche" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H3157 ST12.jpg" /><Typography className="thumbnail-text">&nbsp;Vicenza Eiche</Typography></MenuItem>
                <MenuItem value={"H3317 ST28"} id="H3317 ST28"><Avatar alt="Cuneo Eiche Braun" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/H3317 ST28.jpg" /><Typography className="thumbnail-text">&nbsp;Cuneo Eiche Braun</Typography></MenuItem>
                <MenuItem value={"K2342 AT"} id="K2342 AT"><Avatar alt="Fichte Washed White" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/K2342 AT.jpg" /><Typography className="thumbnail-text">&nbsp;Fichte Washed White</Typography></MenuItem>
                <MenuItem value={"U125 ST9"} id="U125 ST9"><Avatar alt="Sandgelb" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/U125 ST9.jpg" /><Typography className="thumbnail-text">&nbsp;Sandgelb</Typography></MenuItem>
                <MenuItem value={"U201 ST9"} id="U201 ST9"><Avatar alt="Kieselgrau" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/U201 ST9.jpg" /><Typography className="thumbnail-text">&nbsp;Kieselgrau</Typography></MenuItem>
                <MenuItem value={"U211 ST9"} id="U211 ST9"><Avatar alt="Mandelbeige" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/U211 ST9.jpg" /><Typography className="thumbnail-text">&nbsp;Mandelbeige</Typography></MenuItem>
                <MenuItem value={"U769 ST9"} id="U769 ST9"><Avatar alt="Fossilgrau" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/U769 ST9.jpg" /><Typography className="thumbnail-text">&nbsp;Fossilgrau</Typography></MenuItem>
                <MenuItem value={"U999 ST7"} id="U999 ST7"><Avatar alt="Schwarz" src="https://www.woodchecker.com/wp-content/reactpress/apps/dekorplatten/build/Dekore/U999 ST7.jpg" /><Typography className="thumbnail-text">&nbsp;Schwarz</Typography></MenuItem>
                  */}
                </Select>
              </FormControl>
            </Box>
          )}

          {state.materialSelect === "Echtholz-Furniert" && (
            <Box sx={{ width: 300 }}>
              <FormControl fullWidth>
                <Typography>
                  Oberfläche
                </Typography>
                <Select
                  labelId="board_type_label"
                  id="board_type"
                  value={state.SKU}
                  label="Plattentyp"
                  onChange={(e) => {
                    handleSKUChange(e);
                    handleSkuNameChange(e);
                    handleProductIDChange(e);
                  }}
                >
                  {/*}
                  <MenuItem value={"EICRfu105L"} id="EICRfu105L">
                    <Avatar
                      alt="Eiche Crack furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/EICRfu105L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Eiche Crack furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"WEifu109L"} id="WEifu109L">
                    <Avatar
                      alt="Topweiss MT"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/WEifu109L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Wildeiche furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Eifu106L"} id="Eifu106L">
                    <Avatar
                      alt="Eiche furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/Eifu106L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Eiche furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"BUfu103L"} id="BUfu103L">
                    <Avatar
                      alt="Buche gedämpft, furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/BUfu103L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Buche gedämpft, furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"AHfu101L"} id="AHfu101L">
                    <Avatar
                      alt="Ahorn Europ furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/AHfu101L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Ahorn Europ furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"NU Sp FU 108L"} id="NU Sp FU 108L">
                    <Avatar
                      alt="Splintnuss furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/NU Sp FU 108L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Splintnuss furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"EICRBFu104L"} id="EICRBFu104L">
                    <Avatar
                      alt="Eiche Crack broncé furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/EICRBFu104L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Eiche Crack broncé furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"Bu K Fu107L"} id="Bu K Fu107L">
                    <Avatar
                      alt="Kernbuche furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/Bu K Fu107L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Kernbuche furniert
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"BIFu102L"} id="BIFu102L">
                    <Avatar
                      alt="Birke Messer furniert"
                      src="https://www.woodchecker.com/wp-content/reactpress/apps/sonderform-zuschnitte/build/Dekore/BIFu102L.jpg"
                    />
                    <Typography className="thumbnail-text">
                      &nbsp;Birke Messer furniert
                    </Typography>
                  </MenuItem>
                  */}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box sx={{ width: 300 }}>
            <FormControl fullWidth>
              <Typography>
                Plattenstärke
              </Typography>
              <Select
                labelId="board_type_label"
                id="board_type"
                value={state.boardType}
                label="Plattentyp"
                onChange={(e) => {
                  handleBoardTypeChange(e);
                }}
              >
                {!(
                  state.materialSelect === "Echtholz-Furniert" ||
                  state.skuName === "Acai" ||
                  state.skuName === "Aluminium" ||
                  state.skuName === "Black Green" ||
                  state.skuName === "Textile Graphite" ||
                  state.skuName === "Cross Steel GT" ||
                  state.skuName === "Oxid Dark Brown" ||
                  state.skuName ===
                    "Cloud Grey Supermatt Anti-Fingerprint LO" ||
                  state.skuName === "Superfront S1 - 0112 FH" ||
                  state.skuName === "Superfront S1 - 0112 MT" ||
                  state.skuName ===
                    "Raven Black Supermatt Anti-Fingerprint LO" ||
                  state.skuName === "Basalt" ||
                  state.skuName ===
                    "Zuria White Supermatt Anti-Fingerprint LO" ||
                  state.skuName === "Beton Art Perlgrau" ||
                  state.skuName === "Beton Art Schiefergrau"
                ) && (
                  <MenuItem value={"25mm"}>
                    <Typography className="thumbnail-text">25 mm</Typography>
                  </MenuItem>
                )}
                
                {state.materialSelect === "Echtholz-Furniert" && (
                  <MenuItem value={"26mm"}>
                    <Typography className="thumbnail-text">26 mm</Typography>
                  </MenuItem>
                )}

                {!(
                  state.skuName === "Sanitärweiss Spiegelglanz" ||
                  state.skuName ===
                    "Zuria White Supermatt Anti-Fingerprint LO" ||
                  state.skuName === "Weiss Spiegelglanz"
                ) && (
                  <MenuItem value={"38mm"}>
                    <Typography className="thumbnail-text">38 mm</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ width: 300 }}>
            <FormControl fullWidth>
              <Typography>
                Länge L1 in mm
              </Typography>
              <TextField
                  id="length"
                value={stateRechteckig.valueLengthL1}
                size="small"
                onChange={(e) => (stateRechteckig.valueLengthL1 = e.target.value)}
                onClick={(e) => (e.target.select())}
                onBlur={handleBlurLengthL1}
                inputProps={{
                  step: 1,
                  min: 660,
                  max: 1780,
                  inputMode: 'numeric',
                  "aria-labelledby": "input-slider-length",
                }}
              />

              {stateRechteckig.valueLengthL1 > 1780 && (
                <Alert severity="info">Die maximale Länge L1 ist 1780 mm.</Alert>
              )}
              {stateRechteckig.valueLengthL1 < 660 && (
                <Alert severity="info">Die minimale Länge L1 ist 660 mm.</Alert>
              )}
            </FormControl>
          </Box>

          <Box sx={{ width: 300 }}>
            <FormControl fullWidth>
              <Typography>Breite B1 in mm</Typography>
              <TextField
                  id="length"
                value={stateRechteckig.valueWidthB1}
                size="small"
                onChange={e => stateRechteckig.valueWidthB1 = e.target.value}
                onClick={(e) => (e.target.select())}
                onBlur={handleBlurWidthB1}
                inputProps={{
                  step: 1,
                  min: 140,
                  max: 380,
                  inputMode: 'numeric',
                  "aria-labelledby": "input-slider-width",
                }}
              />
            </FormControl>

            {stateRechteckig.valueWidthB1 > 380 && (
              <Alert severity="info">
                Die maximale Breite eines Zuschnitts ist 380 mm.
              </Alert>
            )}
            {stateRechteckig.valueWidthB1 < 140 && (
              <Alert severity="info">
                Die minimale Länge eines Zuschnitts ist 140 mm.
              </Alert>
            )}
          </Box>
          {/*checked === true && boardType === "19mm" && <Box sx={{ width: 240 }}>
         <FormGroup>
          <FormControlLabel control={<Checkbox
          checked={checkedBänder}
          onChange={handleBänderChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} label="Bänderbohrung" />
        </FormGroup>
        </Box>
          */}

          <Box sx={{ width: 300 }}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item xs={3}>
                <TextField
                  id="length"
                  value={state.quantity}
                  size="small"
                  label="Stückzahl:"
                  onChange={(e) => (state.quantity = e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                    "aria-labelledby": "input-quanitity",
                    type: "number",
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography className="priceStyle">
                  <b>{parseFloat(state.price * state.quantity).toFixed(2)} €</b>
                  <br />
                  <Typography className="taxStyle">
                    {" "}
                    inkl. MwSt. <br></br> 
                    {parseFloat(state.freight_cost).toFixed(2)}€ Versandkosten <br></br>
                    und Zustellung in 5-7 Werktagen
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            {state.price === 0 && (
              <Alert severity="info">
                Möglicherweise verhindert dein Browser die korrekte Preisanzeige
                versuche bitte einen anderen Browser.
              </Alert>
            )}
          </Box>

          <Box sx={{ width: 300 }}>
            <Button
              id="single_add_to_cart_button"
              disabled={state.isDisabled}
              onClick={(e) => {
                addToCart(e);
              }}
              variant="contained"
            >
              In den Warenkorb legen
            </Button>
          </Box>

          <Box sx={{ width: 300 }} className="mobileSells">

          </Box>
        </div>
      </div>
    </>,
  ];
}
