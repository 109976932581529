import React, { useRef, useEffect } from "react";
import { useGLTF, Edges, Text } from "@react-three/drei";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { useTexture } from "@react-three/drei";
import state from "./state";
import stateRechteckig from "./state-Rechteckig";
import { proxy, useSnapshot } from 'valtio'

               
export default function Platte(props) {
  

  const snap = useSnapshot(state)
  const snap2 = useSnapshot(stateRechteckig)
var { nodes, material } = useGLTF("https://cdn.shopify.com/s/files/1/0758/0962/0298/files/Platte.gltf?v=1686650121");



material = useTexture({map: 'https://cdn.shopify.com/s/files/1/0758/0962/0298/files/0112_FH.jpg?v=1686650835'})
var scaleX = snap2.valueLengthL1;
var scaleY = snap2.valueWidthB1;

  const group = useRef();


  


  return [(
    <>

    <group ref={group} {...props} dispose={null}>      

      <mesh
        name="Platte"
        castShadow
        receiveShadow
        geometry={nodes.Platte.geometry}
        material={nodes.Platte.material}
        scale={[1*scaleX/1000, 0.001*(state.boardType.replace(/[^0-9]/g,'')), 1*scaleY/1000]}
      ><meshStandardMaterial {...material}/>
      <Edges
        scale={1}
        threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
        color="grey"
        />
      </mesh>
      <group
        position={[0.15-scaleX/1000, -0.02, 0.2-scaleY/1000]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={3}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg_1.geometry}
          material={material.Steel_Brushed_Stainless}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg_2.geometry}
          material={material.Aluminum}
        />
      </group>
      <group
        position={[-0.35+scaleX/1000, -0.02, 0.2-scaleY/1000]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={3}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg001_1.geometry}
          material={material.Steel_Brushed_Stainless}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg001_2.geometry}
          material={material.Aluminum}
        />
      </group>
      {snap2.valueLengthL1 >= 1000 &&     
       <group
        position={[-0.1, -0.02, 0.2-scaleY/1000]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={3}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg002_1.geometry}
          material={material.Steel_Brushed_Stainless}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.y3cv970pq2bg002_2.geometry}
          material={material.Aluminum}
        />
      </group>}

      {/*<Text ref={group}
        color="#594943"
        fontSize={0.2}
        maxWidth={1}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'left'}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
        position={[-1*scaleX/1000, 0, 0]}
        anchorX="right"
        anchorY="top-baseline">Länge: {scaleX + " mm"}
        </Text>

        <Text ref={group}
        color="#594943"
        fontSize={0.2}
        maxWidth={1}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'left'}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
        position={[1*scaleX/1000, 0, 0]}
        anchorX="left"
        anchorY="top-baseline">Breite: {scaleY + " mm"}
        </Text>*/}
    </group>
    </>
)]}


useGLTF.preload("https://cdn.shopify.com/s/files/1/0758/0962/0298/files/Platte.gltf?v=1686650121");