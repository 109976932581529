import React, { useEffect, Suspense } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Html, useProgress } from "@react-three/drei";
/*import { VRCanvas, Interactive, DefaultXRControllers } from '@react-three/xr'*/
import "./App.scss";
import Grid from "@mui/material/Grid";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import state from "./state";
import Formular from "./Formular";
import Platte from "./Platte";
import { useSnapshot } from "valtio";

function CustomLoader() {
  const { progress } = useProgress();
  return <Html center>{progress} % geladen</Html>;
}


function App() {
  const [zoom, setZoom] = React.useState(0);
  const [enableRotation, setEnableRotation] = React.useState(true);

  window.onload = function controlRotation() {
    if (window.innerWidth < 900) {
      setEnableRotation(false);
    }
  };

  const CameraController = () => {
    const { camera, gl } = useThree();
    useEffect(() => {
      const controls = new OrbitControls(camera, gl.domElement);
      controls.enablePan = false;
      controls.minDistance = 1;
      controls.maxDistance = 20;
      //controls.maxPolarAngle = 1.5;
      controls.enableZoom = false;
      controls.enableRotate = enableRotation;

      return () => {
        controls.dispose();
      };
    }, [camera, gl]);
    return null;
  };

  const zoomIn = (event) => {
    setZoom(event.target.value);
  };

  const zoomOut = (event) => {
    setZoom(event.target.value);
  };

  function MoveCamera() {
    const { camera } = useThree();
    useEffect(() => {
      camera.translateZ(zoom);
    }, [zoom, camera]);
  }
  const snap = useSnapshot(state);

  return (
        <>
          <Grid
            container
            rowSpacing={1}
            spacing={1}
            justifyContent="space-evenly"
          >
            <Grid item xs={7} className="model">
              <Canvas
                shadows
                dpr={[1, 2]}
                camera={{ position: [0.5, 1, 1] }}
                style={{ background: "#F5F5F5", height: "500px" }}
              >
                <CameraController dampingFactor={0.3} />
                <ambientLight intensity={0.2} />
                <pointLight
                  castShadow
                  position={[3, 8, 4]}
                  intensity={1}
                  shadow-mapSize-width={2048}
                  shadow-mapSize-height={2048}
                  shadow-camera-far={100}
                  shadow-camera-left={-10}
                  shadow-camera-right={10}
                  shadow-camera-top={10}
                  shadow-camera-bottom={-10}
                />
                <pointLight
                  castShadow
                  position={[-3, -8, -4]}
                  intensity={1}
                  shadow-mapSize-width={2048}
                  shadow-mapSize-height={2048}
                  shadow-camera-far={100}
                  shadow-camera-left={-10}
                  shadow-camera-right={10}
                  shadow-camera-top={10}
                  shadow-camera-bottom={-10}
                />

                <Suspense
                  fallback={
                      <Platte />
                  }
                >
                  <MoveCamera />
                  <Platte />
                </Suspense>
              </Canvas>
            </Grid>
            <Grid item xs={3}><Formular/>
            </Grid>
          </Grid>
        </>
  );
}
export default App;
