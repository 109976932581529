import { proxy } from 'valtio'

const state = proxy({
    dekor: [
        "0112 FH",
        "0112 MT",
        "2162 PE",
        "0741 FH",
        "35252 AT",
        "27045 BS",
        "0071 FH",
        "2171 PE",
        "2306 MT",
        "0085 Weiss SG",
        "34140 RV",
        "44375 DP",
        "44405 DP",
        "0665 NI",
        "0157 NA",
        "K5579 DP",
        "34138 RV",
        "0811 NA",
        "0733 FH",
        "0851 FH",
        "2306 FH",
        "0171 NA",
        "L005 LO",
        "L007 LO",
        "0920 SU",
        "K5574 IR",
        "2166 DP",
        {
            /*"Superfront S1 - 0112 MT", "Superfront S1 - 0112 FH",*/
        },
        "K4347 AT",
        "0175 NA",
        "37706 NM",
        "37755 BS",
        "L009 LO",
        "25726 NM",
        "K5575 IR",
        "0733 SG",
        "K5802 GT",
        "0742 FH",
        "K5414 RO",
        "K5806 GT",
        "1300 FH",
        "0080 NI",
        "H 1180 ST37",
        "H1204 ST19",
        "H1227 TM12",
        "H1371 ST19",
        "H1385 ST40",
        "H1386 ST40",
        "H3157 ST12",
        "H3317 ST28",
        "K2342 AT",
        "U125 ST9",
        "U201 ST9",
        "U211 ST9",
        "U769 ST9",
        "U999 ST7",],
    dekorName: [
        "Topweiss FH",
        "Topweiss matt",
        "Graphitgrau",
        "Birkengrau",
        "Chalet Eiche",
        "Champagner",
        "Fresko",
        "Achatgrau",
        "Gletscherweiss matt",
        "Weiss Spiegelglanz",
        "Eiche Sanremo Classic",
        "Beton Art Perlgrau",
        "Beton Art Schiefergrau",
        "Atram Eiche",
        "Obirbuche geplankt",
        "Oxid Dark Brown",
        "Eiche Sanremo Tabaco",
        "Cello",
        "Sanitärweiss",
        "Winterweiss",
        "Gletscherweiss",
        "Tundra Birke",
        "Zuria White Supermatt Anti-Fingerprint",
        "Raven Black Supermatt Anti-Fingerprint",
        "Silberfichte Natur",
        "Eiche Evoke Sunset",
        "Basalt",
        {
            /*"Topweiss matt Superfront", "Topweiss FH Superfront",*/
        },
        "Cottage Pine Antique",
        "Astfichte",
        "Kernbuche",
        "Noce Sizilia",
        "Cloud Grey Supermatt Anti-Fingerprint",
        "Cameroon Brown",
        "Eiche Evoke Fossil",
        "Sanitärweiss Spiegelglanz",
        "Cross Steel GT",
        "Kieselgrau",
        "Eiche Endgrain Classic",
        "Textile Graphite",
        "Aluminium",
        "Schwarz NI",
        "Halifax Eiche Natur",
        "Lugano Esche Naturhell",
        "Abano Esche Braun",
        "Rifteiche Greige",
        "Casella Eiche Natur",
        "Casella Eiche Braun",
        "Vicenza Eiche",
        "Cuneo Eiche Braun",
        "Fichte Washed White",
        "Sandgelb",
        "Kieselgrau",
        "Mandelbeige",
        "Fossilgrau",
        "Schwarz",],
    dekorFurnier: ["EICRfu105L", "WEifu109L", "Eifu106L", "BUfu103L", "AHfu101L", "NU Sp FU 108L", "EICRBFu104L", "Bu K Fu107L", "BIfu102L"],
    dekorNameFurnier: ["Eiche-Crack-furniert", "Wildeiche-furniert", "Eiche-furniert", "Buche-gedämpft-furniert", "Ahorn-Europ-furniert", "Splintnuss-furniert", "Eiche-Crack-bronce-furniert", "Kernbuche-furniert", "Birke-Messer-furniert"],
    products: ["60896", "60898", "60900", "60947", "60891", "60902", "60907", "60909", "60913", "60911", "60915", "60918", "60921", "60925", "60927", "61334", "61333", "61332", "61331", "61330", "61329", "61328", "61327", "61326", "61323", "61309", "61308", "61307", "61305", "61288", "61289", "61287", "61286", "61284", "61283", "61282" ,"61281", "62009", "62011", "62010", "62013", "62012", "62014", "62015", "62016", "62017", "62019", "62034", "62033", "62035", "62039", "62040"],
    productsFurnier: ["60929", "60931", "60933", "60935", "60937", "60939", "60943", "60941", "60945"],
    productID: '60891',
    SKU: '35252 AT',
    checked: false,
    valueEdge: '2mm',
    boardType: '25mm',
    price: 0,
    tax: 20,
    factor: 2.6,
    skuName: "Chalet Eiche",
    x: Math.floor(Math.random() * (9 - 1 + 1)) + 1,
    isDisabled: false,
    quantity: "1",
    modelType: 'Tischplatte rechteckig',
    materialSelect: 'Dekorplatte',
    isStarted: false,
    freight_cost: 0,
    tablarPrice: 0,
});

export default state